import React, { useContext } from 'react';
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
} from 'carbon-components-react';
import { Link } from 'react-router-dom';
import {
  AppSwitcher20,
  Notification20,
  UserAvatar20,
} from '@carbon/icons-react';

import { GlobalContext } from '../../App';

const AppHeader = () => {
  const ctx = useContext(GlobalContext);

  const { user } = ctx;

  console.log('user', user);


  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label="Carbon Tutorial">
          <SkipToContent />
          <HeaderMenuButton
            aria-label="Open menu"
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName element={Link} to="/" prefix="Chiper">
            Authorizer
          </HeaderName>

          {
            user &&
            <HeaderNavigation aria-label="Authorizer web">
              <HeaderMenuItem element={Link} to="/http-routes">Http routes</HeaderMenuItem>
            </HeaderNavigation>
          }

          {
            user &&
            <SideNav
              aria-label="Side navigation"
              expanded={isSideNavExpanded}
              isPersistent={false}>
              <SideNavItems>
                <HeaderSideNavItems>
                  <HeaderMenuItem href="/repos">Repositories</HeaderMenuItem>
                </HeaderSideNavItems>
              </SideNavItems>
            </SideNav>
          }

          {
            user &&
            <HeaderGlobalBar>
              <HeaderGlobalAction aria-label="Notifications">
                <Notification20 />
              </HeaderGlobalAction>
              <HeaderGlobalAction aria-label="User Avatar">
                <UserAvatar20 />
              </HeaderGlobalAction>
              <HeaderGlobalAction aria-label="App Switcher">
                <AppSwitcher20 />
              </HeaderGlobalAction>
            </HeaderGlobalBar>
          }
        </Header>
      )}
    />);
};

export default AppHeader;
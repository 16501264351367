import React, { useState } from "react";
import { Routes, Route } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Content } from 'carbon-components-react';

import './app.scss';

import firebaseApp from "./firebase";

import Header from './components/Header';

import Login from "./modules/auth/views/Login";
import SendOTP from "./modules/auth/views/SendOTP";
import VerifyOTP from "./modules/auth/views/VerifyOTP";
import Home from "./modules/main/views/Home";

export const GlobalContext = React.createContext();

const App = () => {
  const [user, setUser] = useState({});

  const auth = getAuth(firebaseApp);

  onAuthStateChanged(auth, (currentUser) => {
    setUser(currentUser);
  });

  return (
    <div className="App">
      <GlobalContext.Provider value={{ user }}>
        <Header />
        <Content>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/send-otp" element={<SendOTP />} />
            <Route path="/verify-otp" element={<VerifyOTP />} />
            <Route path="/home" element={<Home user={user} />} />
          </Routes>
        </Content>
      </GlobalContext.Provider>
    </div>
  );
}

export default App;